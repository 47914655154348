.footer {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #252e69;
    color: #fff;
}

.footer_container {
    margin: 0 auto;
}

.footer-row {
    margin-bottom: 40px;
}

.footer__navigation a {
    font-weight: 400;
    font-size: 18px;
    text-decoration-line: none;
    color: #fff;
    margin-right: 30px;
}

.copyright {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    opacity: .3;
    margin-top: 30px;
}

.footer__navigation a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .footer__navigation a {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}