.header {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
    background: #fff;
    z-index: 9999999;
}

.header .logo_page {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.header .name_page {
    color: #000;
    font-weight: 700;
}

.header ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.header .pure-menu-item {
    margin-right: 185px;
}

.header .pure-menu-link {
    font-size: 1em;
    color: #252e69;
    padding: 0.5em 1em;
    font-weight: 700;
}

.standforukraine {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.cl__1 {
    background-color: #0057B7;
}

.cl__2 {
    background-color: #FFDD00;
}

.standforukraine .cl__1 {
    flex: 1;
    display: inline-block;
    height: 2px;
    width: 100%;
}

.standforukraine .cl__2 {
    flex: 1;
    display: inline-block;
    height: 2px;
    width: 100%;
}

.menu_reponsive {
    position: absolute;
    right: 0;
    right: -200px;
    top: -10px;
    display: none;
}

.menu_reponsive svg {
    height: 30px;
}
.sidepanel  {
    width: 100%;
    position: fixed;
    /* z-index: 1; */
    top: 0;
    left: 0;
    background-color: #ededed;
    overflow-x: hidden;
    transition: 0.5s;
    margin-top: 50px;
    text-align: center;
  }

/* ======================reponsive======================== */
@media screen and (min-width:769px) {
    .sidepanel div {
        display: none;
    }
}
@media screen and (max-width:768px) {
    .pure-menu-item {
        display: none;
    }
    .menu_reponsive {
        display: block;
    }
    .btn__download  {
        width: 75%;
    }
}

@media screen and (max-width:1000px) {
    .header .pure-menu-item {
        margin-right: 100px;
    }
}