body {
    margin: 0;
    padding: 0;
    font-family: "Dancing_Script variant0";
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ol {
    list-style: none;
}

.btn__download {
    border: none;
    background-color: offwhite;
    font-size: 30px;
    padding: 20px 40px;
    text-transform: capitalize;
    cursor: pointer;
    transform: all 0.3s;
    position: relative;
}

.btn__download:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -5px;
    left: -5px;
    border-top: 2px solid tomato;
    border-left: 2px solid tomato;
    transition: all 0.25s;
}

.btn__download:hover:before,
.btn__download:hover:after {
    width: 104%;
    height: 110%;
}

.btn__download:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -5px;
    right: -5px;
    border-bottom: 2px solid tomato;
    border-right: 2px solid tomato;
    transition: all 0.30s;
}
